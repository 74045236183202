.login-wrapper {
    background-color: rgba(249, 250, 251, 1);
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
}
.login-subwrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    /* height: 90vh; */
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    /* max-height: 700px; */
    height: fit-content;
}

.login-subwrapper img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* height: 90vh; */
    max-height: 670px;
    height: 100%;
}
.login-subwrapper .login-form {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.login-form h3 {
    padding-left: 3rem;
    margin-bottom: 1rem;
    color: rgba(36, 38, 45.1);
    font-size: 1.8rem;
    font-weight: 600;
    font-family: inherit;
}
.login-form form {
    padding-left: 3rem;
    display: grid;
    gap: 1.3rem;
    align-items: center;
    width: 90%;
}
.form-control {
    display: grid;
    width: 90%;
    font-family: inherit;
    gap: 0.1rem;
}
.form-control label {
    color: #24262d;
    font-size: 1rem;
}
.form-control input {
    width: 100%;
    height: 2.6rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.form-control input:focus {
    background-color: white;
}
.form-control p {
    margin: 0;
}
.error {
    color: rgba(249, 128, 128, 1);
    font-size: 1rem;
    font-weight: inherit;
}
.login-form form input[type="submit"] {
    width: 92%;
    height: 3rem;
    background-color: rgba(14, 159, 110, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.login-form form input[type="submit"]:hover {
    background-color: #0d8b61;
}
.border {
    padding-bottom: 2rem;
    width: 90%;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
}
.social-btns {
    padding-top: 2rem;
    display: grid;
    gap: 1rem;
    width: 90%;
    padding-left: 3rem;
}
.social-btns button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 90%;
    height: 3rem;
    border: 1px solid rgba(229, 231, 235, 1);
    cursor: pointer;
    border-radius: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 0.95rem;
    transition: 500ms;
}
.social-btns button p {
    margin: 0;
}
.social-btns .facebook:hover {
    background-color: #1c64f2;
    color: white;
}
.social-btns .google:hover {
    background-color: #f05252;
    color: white;
}
.login-links {
    display: grid;
    gap: 0.7rem;
    padding-top: 2rem;
    padding-left: 3rem;
}
.login-links a {
    color: #0e9f6e;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;
}
.login-links a:hover {
    text-decoration: underline;
}
/* @media screen and (min-width: 1500px) {
    .login-subwrapper {
        height: 650px;
    }
} */
@media screen and (max-width: 768px) {
    .login-wrapper {
        height: 100%;
        padding: 2rem 0;
        align-items: unset;
        padding-top: 5rem;
        justify-items: center;
        /* min-width: 80vw; */
    }
    .login-subwrapper {
        max-height: 100%;
        grid-template-columns: 1fr;
        gap: 0;
        /* min-width: 70%; */
        /* height: 100%; */
    }
    .login-subwrapper img {
        /* height; */
        width: 100%;
        max-height: 350px;
        /* height: 100%; */
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .login-form form {
        width: 100%;
        padding-left: 0.5rem;
        /* min-width: 330px; */
        width: 100%;
    }
    .login-subwrapper .login-form {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-bottom: 2rem;
    }
    .social-btns,
    .login-links {
        padding-left: 0.5rem;
        width: 100%;
    }
}
/* --------------sign-up------------ */
.signup-subwrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.signup-subwrapper {
    /* align-items: center; */
    /* height: 90%; */
    margin: 3rem 0;
    height: fit-content;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.signup-subwrapper img {
    width: 400px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.form-control select {
    width: 100%;
    height: 2.6rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.signup-subwrapper .login-form h3 {
    padding: 2rem 0 0 3rem;
}
.signup-subwrapper .login-form {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.signup-subwrapper .login-links {
    padding: 2rem 0 2rem 3rem;
}
.form-control-terms {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}
.form-control-terms input {
    zoom: 1.3;
}
@media screen and (max-width: 767px) {
    .signup-subwrapper {
        grid-template-columns: 1fr;
        margin: 0;
    }
    .signup-subwrapper img {
        /* height; */
        width: 100%;
        max-height: 350px;
        /* height: 100%; */
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .signup-subwrapper .login-links {
        margin-left: 1rem;
        padding: 1rem 0;
    }
}
.forgot-password {
    align-items: center;
}
