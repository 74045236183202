* {
    margin: 0;
    padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-checkbox {
    margin-left: 0.6rem;
}
.react-bootstrap-table table thead {
    border-bottom: 1px solid #e5e7eb !important;
}
.react-bootstrap-table table th {
    border: none;
    background-color: rgba(229, 231, 235, 0.6);
    padding: 1rem;
    
    text-align: center;
    
}
.configclass .react-bootstrap-table table td {
  word-break: break-all;
}
.react-bootstrap-table table tbody tr td {
    color: #24262d !important;
    font-size: 12px;
    /* text-align: center; */
    font-family: 'Open Sans', sans-serif;
    border: none;
    font-weight: 450;
    padding: 1rem;
    vertical-align: middle;
    cursor: pointer;
}
.react-bootstrap-table table tbody tr {
    background-color: white;
    border-bottom: 1px solid #e5e7eb;
    text-align: center;
}
.category-table {
    border: 1px solid #e5e7eb;
    display: grid;
    overflow: scroll;
    min-width: 800px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.category-table::-webkit-scrollbar {
    display: none;
   
   
}
.category-table table {
  min-width: 800px;
  width: 100%;
}
/* .internal-transfer tr:hover{
  background-color: #ddd;
} */
.react-bootstrap-table table {
    table-layout: auto;
    overflow: scroll;
    width: 100%;
    min-width: 1000px;
}
.react-bootstrap-table table::-webkit-scrollbar {
    display: none;
}
.react-bootstrap-table-pagination {
    --bs-gutter-x:: 0;
}
.table > :not(:first-child) {
    border-top: 0;
}
.rti--container {
    max-width: 590px;
    --rti-tag: #edf2f7 !important;
  /* --rti-main: #3182ce; */
  --rti-radius: 0.5rem;
  --rti-s: 1rem; 
}

.react-select-container{
    width: 450px;
}

/* -------react-tags------- */


.ReactTags__tags {
    position: relative;
  }

  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }

  /* Styles for the input */
  /* .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    min-width: 150px;
  }

  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  } */

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
      margin-bottom: 1rem;
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }
  .ReactTags__selected span.ReactTags__tag:first-child {
    margin-left: 0px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    top:60px;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    /* text-decoration: underline; */
    margin: 0;
    padding: 0;
    background: none;
    /* font-weight: 600; */
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }
/* .config-table tr td {
  word-wrap: break-word;
  width: 150px;
} */