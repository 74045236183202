* {
    margin: 0;
    padding: 0;
}
.TopNav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    padding: 1rem 3rem;
    z-index: 10;
    /* position: fixed; */
    top: 0;
    right: 0;
    width: 100%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.user {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.user p {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
}
.user p span {
    font-weight: 550;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.notifications {
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.notifications > p {
    position: absolute;
    top: -0.3rem;
    left: -0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
    background-color: red;
    height: 1.2rem;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 1.2rem;
    border-radius: 50%;
}
.user img {
    width: 2.3rem;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.user-notifications {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 2.1rem;
    left: -21rem;
    height: 22rem;
    width: 22rem;
    z-index: 100000;
    background-color: white;

    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.user-notifications::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.user-notifications::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.user-notifications::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.user-notifications::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.item {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
}
.item:hover {
    background-color: rgba(249, 250, 251, 1);
}
.item-data {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.item-data .desc {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(112, 114, 117, 1);
    font-size: 14px;
    font-weight: 600;
}
.item-icon {
    border-radius: 50%;
    border: 1px solid grey;
}

.item-data .stockout {
    color: #f05252;
    font-size: 11px;
    background-color: #fde8e8;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 10px;
    font-weight: 600;
}
.item-data .NewOrder {
    color: #0e9f6e;
    font-size: 11px;
    background-color: #def7ec;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 10px;
    font-weight: 600;
}
.date-time {
    color: rgba(112, 114, 117, 1);
    font-size: 13px;
    padding: 0 1rem;
}
@media screen and (max-width: 500px) {
    .user-notifications {
        height: 16rem;
        width: 17rem;
        left: -15rem;
    }
    .item-data .desc {
        width: 60%;
    }
    .date-time {
        padding: 0 0.5rem;
    }
}
/* -----------sideNav------------- */
.Container {
    background-color: #fff;
    min-height: 92vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    position: fixed;
    width: 190px;
    left: 0;
    z-index: 100;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    overflow: scroll;
    box-shadow: 0 4px 60px rgba(0, 0, 0, 0.08);
    padding-top: 0.5rem;
    user-select: none;
    transition: width 0.3s ease;
}
.Container a {
    user-select: none;
}
.Container::-webkit-scrollbar {
    display: none;
}
.seo {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
}
.seo {
    display: grid;
    gap: 0.5rem;
    /* overflow: scroll; */
}
.seo-item {
    display: flex;
    font-size: 0.9rem;
    cursor: pointer;
    gap: 1rem;
    color: #707275;
    /* color: white; */
    padding: 0 1rem;
}

.seo-item-nav {
    display: grid;
    gap: 0.3rem;
    overflow: hidden;
}

.seo-item p {
    /* color: white; */
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    padding: 0;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: "Open Sans", sans-serif;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                 white-space: nowrap; /* Prevent the paragraph from wrapping to the next line */
}
.cancel {
    background-color: red;
    color: white;
}
.hide {
    width: 70px;
}

.hamburger {
    width: 100%;
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
    justify-items: center;
    align-items: center;
    margin: 1rem 0;
    /* padding: 0.5rem; */
}
.hamburger > img {
    cursor: pointer;
    width: 100%;
    max-width: 6rem;
    align-self: center;
    transition: opacity 0.3s ease;
    flex-grow: 2;
    justify-self: center;
    text-align: center;
}
.hide .hamburger img {
    display: none;
}
.hamburger-icon {
    cursor: pointer;
    border: none;
    padding: 0 !important;
    outline: none;
    color: black;
}
.hide .hamburger-icon {
    right: 10px;
}
.navlinks {
    display: grid;
    gap: 0.8rem;
    margin-top: 2rem;
}
.nav-icon {
    font-size: 1.2rem;
    color: inherit;
}
.navlinks a,
.Container button {
    align-items: center;
    display: flex;
    font-family: Open Sans, sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    color: #707275;
    gap: 1rem;
    text-decoration: none;
}
.navlinks a {
    padding: 0.1rem 0.9rem;
    border-left: 4px solid #fff;
}
.navlinks a:hover {
    color: #0e9f6e;
    /* background-color: white; */
}
.seo-item-nav a {
    padding: 0.5rem;
}
.navlinks a span {
    /* margin-top: 0.4rem; */
    overflow: hidden;
}

.sub-link-hide {
    padding-left: 0 !important;
    border: none !important;
}

.navlinks a img,
.Container button img {
    width: 1.1rem;
}

.navActive {
    color: #0e9f6e !important;
    border-left: 4px solid #0e9f6e !important;
}
.navInactive {
    color: #707275;
}
.Container button {
    border: none;
    display: flex;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
    padding: 0.1rem 1.3rem;
}
.Container button p {
    margin-bottom: 0;
}
.Container button img {
    padding: 2rem 0 0 0;
}
.logout {
    margin-top: 1rem;
}
.hidden {
    opacity: 0;
}
.hide .logout {
    /* padding: 1rem; */
    border-top: 3px solid white;
}

.hide button image {
    padding: 0;
}
/* .hide .Container button p {
    padding: 0;
} */
.img-size {
    width: 3rem !important;
}
.hide .navlinks {
    gap: 1.5rem;
}
.hide .navlinks a,
.hide .seo-item {
    background-color: transparent;
    transition: background-size 0.5s;
    background-image: linear-gradient(to right, white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}
.hide .seo-item {
    /* padding: 1rem; */
    user-select: none;
    outline: none;
    border: none;
}
.hide .navlinks a:hover,
.hide .seo-item:hover {
    color: #0077b6;
    background-size: 100% 100%;
}
.seo-item-nav a {
    padding-left: 3rem;
}

@media screen and (max-width: 900px) {
    .Container {
        overflow: scroll;
    }
    .backdrop {
        user-select: none;
        position: fixed;
        overflow: hidden;
        width: 100vw;
        min-height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.6);

        z-index: 10;
    }
    .Container {
        gap: 1rem;
        /* width: 70px; */
    }
    /* .seo-item p {
        display: none;
    } */
}
